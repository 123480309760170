<!--
 * @Description: 新闻资讯
 * @Author: wangqianan
 * @Date: 2022-08-4 16:03:27
-->

<template>
	<div class="w_auto information">
		<section class="cnfin-main-news ">
			<!-- 图文样式一 -->
			<section class="stylesOne" v-if="floorInfo && floorInfo.showStyle == 0"
				:style="{ backgroundImage: 'url(' + floorInfo.typeImg + ')' }">
				<section class="notice_bar_length1">
					<div class="titlebox">
						<div class="titlebg"><span class="floorName">{{ floorInfo.floorName }}</span></div>
						<!-- <div class="pageset">
                  <div>
                    <i class="el-icon-back"></i><span>上一条</span>
                  </div>
                  <span style="margin:0 10px">|</span>
                  <div>
                    <span>下一条</span><i class="el-icon-right"></i>
                  </div>
                </div> -->
					</div>
					<div class="noticebar" v-for="(list, index) in inforBlocks" :key="index">
						<div v-for="(infor, index) in list" :key="index" class="notice_item" @click="newsclick2(infor)">
							<div class="imgnotice">
								<img v-if="infor.img_url != ''" :src="infor.img_url" alt="">
							</div>
							<p class="article_name">{{ infor.article_name }}</p>
							<el-divider />
							<div class="notice_b">
								<div><span class="d-icon">D</span><span>{{ infor.createdAtDate }}</span></div>
								<div class="d-text"><span>阅读正文</span><img :src="rightArrowImg" alt=""
										style="width: 15px;"></div>
							</div>
						</div>
					</div>
				</section>
			</section>
			<!-- 图文样式二 -->
			<section class="stylesTwo" v-if="floorInfo && floorInfo.showStyle == 1"
				:style="{ backgroundImage: floorInfo.typeImg != '' ? 'url(' + floorInfo.typeImg + ')' : 'url(' + newsBg + ')' }">
				<div class="box">
					<div class="n_left" v-if="allNews.length > 0">
						<h3>{{ floorInfo.floorName }}</h3>
						<div class="swiper-slide swiper-slide-active">
							<a href="javascript:void();" @click="newsclick2(allNews[0])">
								<div>
									<img :src="allNews[0].img_url" style="width:100%" />
								</div>
								<h3 style="font-size: 20px;font-weight: 700;margin: 15px 0 0 0" class="article_name">
									{{ allNews[0].article_name }}
								</h3>
								<p class="summary">{{ item.summary }}</p>
							</a>
							<el-divider class="newsdivider"></el-divider>
							<div @click="newsclick2(allNews[0])"
								style="cursor: pointer; display: flex;align-items: center;float: right;"><span
									style="color:#fff;font-size: 15px;">阅读正文</span><img
									style="width:20px;height:20px;margin-left:10px" :src="rightArrowImg" alt=""></div>
						</div>
					</div>
					<div class="moreBtn2" @click="moreNews(floorInfo)">More</div>
					<div class="n_right">
						<div v-for="item in allNews.slice(1).slice(0, 5)" :key="item.article_id" class="newItem">
							<div @click="newsclick2(item)"
								style="display: flex;align-items: flex-start;flex-wrap: nowrap;justify-content: center;">
								<dl class="datenews">
									<dd> {{ new Date(item.createdAt).getFullYear() }}-{{
										new Date(item.createdAt).getMonth() + 1
									}}</dd>
									<dt>
										{{ item.createdAt.substring(8, 10) }}
									</dt>
								</dl>
								<div>
									<p class="article_name">{{ item.article_name }} </p>
									<p class="summary">{{ item.summary }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- 图文样式三 :style="{backgroundImage:'url('+floorInfo.typeImg+')'}"-->
			<section class="stylesTextThree" v-if="floorInfo && floorInfo.showStyle == 2">
				<div>
					<img :src="floorInfo.bgImage && floorInfo.bgImage != '' ? floorInfo.bgImage : bgbottomimg" alt=""
						style="width: 100%;height: 175px">
				</div>
				<div style="display: flex;justify-content: center;max-width: 1500px;">
					<div class="notice_bar_length3" v-for="(list, index) in inforBlocks" :key="index">
						<div class="news3">
							<div class="m-channel-title m-channel-title-yw">
								<div class="channel-title-l icon-zsym icon-yw fl">
									<img class="icon123" :src="icon1" alt="" v-if="index == 0">
									<img class="icon123" :src="icon2" alt="" v-if="index == 1">
									<img class="icon123" :src="icon3" alt="" v-if="index == 2">
									<a style="color: #fff; cursor: pointer; margin-left: 15px;"
										@click="moreClick(articleTypeId(list), articleTypeName(list))">{{
											articleTypeName(list) }}</a>
								</div>
								<div class="channel-title-r fr">
									<a @click="moreClick(articleTypeId(list), articleTypeName(list))"></a>
								</div>
							</div>
							<div class="newsimg">
								<img class="" :src="floorInfo.typeImg" alt="" v-if="index == 0">
								<img class="" :src="floorInfo.typeImgOne" alt="" v-if="index == 1">
								<img class="" :src="floorInfo.typeImgTwo" alt="" v-if="index == 2">
							</div>
							<ul class="ui-yw-wrap">
								<li v-for="(infor, index) in list" :key="index">
									<a style="cursor: pointer" @click="newsclick2(infor)">{{ infor.article_name }}</a>
								</li>
							</ul>
							<div class="morebtn">
								<a @click="moreClick(articleTypeId(list), articleTypeName(list))">
									更多
									<img :src="rightArrowImg" alt="">
								</a>
							</div>
						</div>
					</div>
				</div>

			</section>
			<!-- 文本样式一  -->
			<section class="stylesTextOne" v-if="floorInfo && floorInfo.showStyle == 3"
				:style="{ backgroundImage: 'url(' + floorInfo.typeImg + ')' }">
				<section class="notice_bar_length1_1">
					<div class="bar">
						<div class="notice_box" v-for="(list, index) in inforBlocks" :key="index">
							<el-divider class="divider1_1"></el-divider>
							<div v-for="(infor, index) in list" :key="index" class="notice_item"
								@click="newsclick2(infor)">
								<div class="dateShow" v-if="index == 0">
									<p class="p1">{{ infor.month }}</p>
									<p class="p2">{{ infor.day }}</p>
								</div>
								<div v-if="index == 0" style="margin-left:40px">
									<p style="font-weight: 700;color:#000">{{ infor.article_name }}</p>
									<p class="summary">{{ infor.summary }}</p>
								</div>
								<div v-else
									style="width:100%;display: flex;align-items: center;justify-content: space-between;">
									<div>
										<p style="display: flex;align-items: center;color:gray"><span
												class="pointicon"></span>{{
													infor.article_name }}</p>
									</div>
									<div style="display: flex;align-items: center;padding-right: 20px">
										<img :src="jiantouhui" alt="" style="width: 20px;height: 20px;margin-right:6px">
										<span style="color:#C9CACA">{{ infor.createdAtDate }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="reservation_bar" v-if="qrInfo">
							<img :src="qrInfo.adImg" alt="" class="reservationImg" />
							<p class="text"><span>{{ qrInfo.adName }}</span></p>
						</div>
					</div>
				</section>
			</section>

		</section>
	</div>
</template>

<script>
export default {
	data() {
		return {
			inforBlocks: [],
			allNews: [],
			floorInfo: null,
			newsBg: require("@/assets/images/home/8.png"),
			rightArrowImg: require("@/assets/images/home/jiantoulv.png"),
			jiantouhui: require("@/assets/images/home/jiantouhui.png"),
			reservationImg: require("@/assets/images/home/reservation.png"),
			bgbottomimg: require("@/assets/images/home/10.png"),
			icon1: require("@/assets/images/home/1.png"),
			icon2: require("@/assets/images/home/2.png"),
			icon3: require("@/assets/images/home/3.png"),
			icon11: require("@/assets/images/home/11.png"),
			icon22: require("@/assets/images/home/22.png"),
			icon33: require("@/assets/images/home/33.png"),
			qrInfo: null
		};
	},
	props: {
		item: {
			type: Object,
			default: () => { },
		},
	},
	mounted() {
		this.floorInfo = this.item
		this.selectAritcleByTypeId(this.item);
		this.$api
			.selectContentList({
				channelId: this.$http.channelId,
				token: this.token,
				codes: "000005",
			})
			.then((res) => {
				if (res.code == 10000) {
					this.qrInfo = res.data[0]
				}
			});
	},
	methods: {
		set(lists) {
			lists.map((t) => {
				this.selectAritcleByTypeId(t);
			});
		},
		async selectAritcleByTypeId(t) {
			const params = [t.typeId, t.typeIdOne, t.typeIdTwo];
			const inforBlocks = [];
			for (let index = 0; index < t.blockNumber; index++) {
				const res = await this.$api.getChannelArticleByTypeIdApi({
					channelId: this.$http.channelId,
					pageSize: t.showNumber,
					isIndexShow: 1,
					pageNum: 1,
					typeId: params[index],
					token: localStorage.getItem("pc_token"),
				});
				const news = res.data.data.slice(0, t.showNumber);
				news["typeNameOne"] = t?.typeNameOne;
				if (news?.length) {
					inforBlocks.push(news);
					// if (!params[index]) {
					//   news[0].article_type_name = "全部分类";
					// }
				}
				this.allNews = this.allNews.concat(news);
				if (this.allNews[0]) {
					this.allNews[0].content = this.allNews[0].content
						.replaceAll("color: #333333", "color: white")
						.replaceAll("font-size: 20px;", "font-size: 14px;");
				}
			}
			inforBlocks.map(i => {
				i.map(k => {
					k.createdAtDate = k.createdAt.split('T')[0]
					k.createdAtDates = k.createdAtDate.split('-')
					k.day = k.createdAtDates[2]
					k.month = ''
					switch (k.createdAtDates[1]) {
						case '01':
							k.month = 'Jan'
							break;
						case '02':
							k.month = 'Feb'
							break;
						case '03':
							k.month = 'Mar'
							break;
						case '04':
							k.month = 'Apr'
							break;
						case '05':
							k.month = 'May'
							break;
						case '06':
							k.month = 'Jun'
							break;
						case '07':
							k.month = 'Jul'
							break;
						case '08':
							k.month = 'Aug'
							break;
						case '09':
							k.month = 'Sept'
							break;
						case '10':
							k.month = 'Oct'
							break;
						case '11':
							k.month = 'Nov'
							break;
						case '12':
							k.month = 'Dec'
							break;
						default:
							''
							break;
					}
				})
			})
			this.inforBlocks = inforBlocks;
			// if(this.inforBlocks.length ==1){
			//   this.inforBlocks[0].push(this.inforBlocks[0][0])
			// }
		},
		blockImage(index) {
			if (index === 0) {
				return this.item.typeImg;
			} else if (index === 1) {
				return this.item.typeImgOne;
			} else if (index === 2) {
				return this.item.typeImgTwo;
			}
		},
		articleTypeId(list) {
			if (list[0].article_type_id.indexOf("|") >= 0) {
				return list[0].article_type_id.split("|")[1];
			} else {
				return list[0].article_type_id;
			}
		},
		articleTypeName(list) {
			if (list[0].article_type_name.indexOf("|") >= 0) {
				return list[0].article_type_name.split("|")[1];
			} else {
				return list[0].article_type_name;
			}
		},

		// 点击更多按钮
		moreClick(typeId, name) {
			this.$router.push({
				path: "/news/index",
				query: {
					typeId: typeId,
					name
				},
			});
		},
		moreNews(v) {
			this.$router.push({
				path: "/news/index",
				query: {
					typeId: v.typeId,
					name: v.moduleName
				},
			});
		},
		newsclick(id) {
			this.$router.push({
				path: "/news/detail",
				query: {
					id: id
				},
			});
		},
		newsclick2(item) {
			console.log(11111, item)
			this.$router.push({
				path: "/news/index",
				query: {
					aId: item.article_id,
					atId: item.article_type_id,
					tName: item.article_type_name
				},
			});
		},
	},
};
</script>

<style scoped lang="scss">
.information {
	padding: 0 0;
	max-width: 1500px;
	margin: 0 auto;

	&.w_auto {
		// margin-top: 20px;
	}

	.stylesOne {
		.notice_bar_length1 {
			display: flex;
			justify-content: flex-start;
			margin-top: 30px;
			padding-top: 20px;
			padding-bottom: 20px;

			.titlebox {
				.titlebg {
					width: 130px;
					height: 130px;
					background-image: url('../../../assets/images/home/titlebg.png');
					background-size: 100% 100%;
					position: relative;

					.floorName {
						font-size: 25px;
						text-align: center;
						color: #fff;
						font-weight: bolder;
						width: 60px;
						display: inline-block;
						word-break: break-all;
						white-space: 2px;
						letter-spacing: 2px;
						position: absolute;
						top: 12px;
						left: 14px;
					}
				}

				.pageset {
					display: flex;
					align-items: center;
					margin-top: 80px;
				}

			}

			.noticebar {
				margin-left: 50px;
				display: flex;
				overflow-x: auto;

				.notice_item {
					width: 400px;
					margin: 0 20px;
					cursor: pointer;

					.imgnotice {
						width: 400px;
						height: 240px;

						img {
							width: 400px;
							height: 240px;
						}
					}

					.article_name {
						font-size: 16px;
						font-weight: 700;
						color: #000;
						margin: 15px 0 20px 0;
					}

					.notice_b {
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #B3B3B3;

						.d-icon {
							display: inline-block;
							width: 20px;
							height: 20px;
							text-align: center;
							line-height: 20px;
							border-radius: 10px;
							color: #fff;
							font-weight: 600;
							background-color: #B3B3B3;
							font-size: 12px;
							margin-right: 5px;
							position: relative;
							top: -1px;
						}

						.d-text {
							display: flex;
							align-items: center;

							img {
								margin-left: 4px
							}
						}
					}
				}
			}
		}
	}

	.stylesTwo {
		background-size:100% 100%;
		.box {
			width: 100%;
			height: 620px;
			background-size: 100% 100%;
			box-sizing: border-box;
			padding: 60px 150px 0 150px;
			display: flex;
			justify-content: space-between;
			position: relative;

			.moreBtn2 {
				position: absolute;
				top: 70px;
				right: 50px;
				color: #fff;
				font-size: 16px;
				font-weight: normal;
				cursor: pointer;
			}

			.n_left {
				width: 45%;

				h3 {
					color: #fff;
					font-size: 35px;
					font-weight: 400;
					margin-bottom: 30px;
				}

				.swiper-slide {
					overflow: hidden;
					height: 450px;

					.article_name {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.newsdivider {
						margin: 10px 0 8px 0;
						background-color: #6F9C8B;
					}
				}

				.swiper-slide div img {
					width: 100%;
					width: 510px;
					height: 320px;
					-webkit-transition: all 0.5s;
					transition: all 0.5s;
				}

				.swiper-slide:hover div img {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
					-webkit-transition: all 0.5s;
					transition: all 0.5s;
				}

				.swiper-slide p {
					font-size: 18px;
					color: #fff;
					font-weight: 400;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 20px auto;
				}
			}

			.n_right {
				width: 50%;
				padding-top: 75px;
				height: 490px;
				display: grid;



				.datenews {
					background-color: #0C7443;
					width: 65px;
					height: 60px;
					padding: 8px 0 0 0;
					margin-right: 20px;
				}

				.article_name {
					width: 550px;
					font-size: 20px;
					font-weight: bold;
					color: #fff;
					overflow: hidden;
					/*隐藏多出部分文字*/
					text-overflow: ellipsis;
					/*用省略号代替多出部分文字*/
					display: -webkit-box;
					/* 显示多行文本容器 */
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				.summary {
					width: 500px;
					font-size: 15px;
					font-weight: lighter;
					color: #fff;
					overflow: hidden;
					/*隐藏多出部分文字*/
					text-overflow: ellipsis;
					/*用省略号代替多出部分文字*/
					display: -webkit-box;
					/* 显示多行文本容器 */
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					margin-top: 10px;
				}
			}

			.newItem:hover {
				background: rgba(255, 255, 255, 0.1);
				transition: 0.5s;
				-moz-transition: 0.5s;
				-ms-transition: 0.5s;
				-o-transition: 0.5s;
				-webkit-transition: 0.5s;
			}

			.newItem {
				border-bottom: 1px #6F9C8B solid;
				transition: 0.5s;
				-moz-transition: 0.5s;
				-ms-transition: 0.5s;
				-o-transition: 0.5s;
				-webkit-transition: 0.5s;
				margin-bottom: 10px;
				cursor: pointer;

				dl {
					width: 100px;
					float: left;
					height: 100px;
					text-align: center;
					color: #fff;
					// font-family: "思源宋体 CN Medium";
				}

				dd {
					font-size: 14px;
				}

				dt {
					font-size: 22px;
				}
			}
		}
	}

	.stylesTextThree {
		margin-top: -30px;
		height: 460px;
		margin-bottom: 25px;

		.notice_bar_length3 {
			max-height: 485px;
			position: relative;
			top: -130px;
			width: 30%;

			.news3 {

				background-color: #fff;
				//       max-width: 420px;
				// min-width: 390px;
				min-height: 400px;
				margin: 0 auto;

				.channel-title-l {
					.icon123 {
						position: relative;
						top: 5px;
					}
				}

				.newsimg {
					width: 100%;
					height: 160px;
					margin-bottom: 10px;

					img {
						width: 100%;
						height: 160px;
					}
				}
			}
		}

		.notice_bar_length3:nth-child(2) {
			margin: 0 40px;
		}
	}

	.stylesTextOne {
		.notice_bar_length1_1 {
			margin-top: 40px;
			margin-bottom: 50px;

			.divider1_1 {
				background-color: #007800;
				height: 4px;
				border-radius: 5px;
			}

			.bar {
				display: flex;
				justify-content: space-between;

				.notice_box {
					width: 89%;
					flex-wrap: wrap;

					.notice_item:nth-child(2n-1) {
						background-color: #ECF1EC;
					}

					.notice_item {
						width: 100%;
						padding: 20px 0px 15px 20px;
						margin-right: 5px;
						border-bottom: 1px solid gainsboro;
						cursor: pointer;
						display: flex;

						.summary {
							font-size: 15px;
							color: #898989;
							margin-top: 8px;
						}

						.dateShow {
							width: 60px;
							height: 60px;
							background: #007800;
							text-align: center;

							.p1 {
								font-weight: 700;
								margin-top: 6px;
								font-size: 16px;
								color: #fff;
							}

							.p2 {
								font-size: 18px;
								color: #fff;
								font-weight: 700px;
							}
						}

						.pointicon {
							display: inline-block;
							width: 10px;
							height: 10px;
							border-radius: 20px;
							background-color: #898989;
							margin-right: 20px;
						}
					}
				}

				.reservation_bar {
					cursor: pointer;
					width: 120px;
					height: 153px;
					background-image: url('../../../assets/images/home/qrbox.png');
					background-size: 100% 100%;
					position: relative;

					.text {
						color: #fff;
						font-size: 16px;
						font-weight: 700;
						text-align: center;
						width: 100%;
						position: absolute;
						bottom: 10px;
					}

					.reservationImg {
						widows: 90px;
						height: 90px;
						position: absolute;
						top: 15px;
						left: 14px;
					}

				}
			}
		}
	}

	.new {
		display: flex;
		align-items: center;
		font-size: 18px;
	}

	.more {
		font-size: 14px;
	}

	.more span {
		cursor: pointer;
	}


}

.new ::v-deep .el-icon-caret-right {
	font-size: 25px;
}

.threeBox {
	max-height: 400px;
}


.m-channel-title-yw {
	background: #015739;
	padding: 0 15px;
	border-bottom: none;
	height: 46px;
}

.m-channel-title-yw .icon-zsym {
	height: 46px;
	line-height: 46px;
}

.ui-yw-wrap {
	padding-left: 20px;
	background-color: #fff;
	overflow-y: auto;
	height: 160px;
}

.ui-yw-wrap li {
	// height: 40px;
	margin-bottom: 14px;
	padding-left: 20px;
	background: url("../../../assets/images/personal/icon_listd.png") -9px -2px no-repeat;
}

.ui-yw-wrap li a {
	font-size: 14px;
	font-weight: 300;
	color: #333;
	line-height: 20px;
}

.morebtn {

	color: #C4C4C4;
	font-size: 13px;
	position: absolute;
	bottom: 20px;
	right: 20px;
	cursor: pointer;

	img {
		width: 15px;
		position: relative;
		top: 1px
	}
}

/* å¿«è®¯ */
.xhcj_left_01,
.imageWrap {
	width: 950px;
}

.xhcj_left_01 .hotWordTxt {
	width: 840px;
	height: 38px;
}

.xhcj_right_01,
.xhcj_right_01 img {
	width: 244px;
	height: 36px;
}

.xhcj_right_01 {
	margin-top: 3px;
}

.xhcj_left_01 .hotWord {
	height: 40px;
	background: #ffffff;
	border: 1px solid #f0f0f0;
}

.xhcj_ci {
	overflow: hidden;
	margin: 20px 0;
}

.xhcj_ci li {
	float: left;
	height: 25px;
	line-height: 25px;
	width: 120px;
	text-align: center;
}

.xhcj_ci li a {
	color: #fff;
	font-size: 14px;
}

.xhcj_ci li:nth-child(1) {
	background: #cd282e;
}

.xhcj_ci li:nth-child(2) {
	background: #3683eb;
}

.xhcj_ci li:nth-child(3) {
	background: #ff6801;
}

.xhcj_ci li:nth-child(4) {
	background: #808000;
}

.xhcj_ci li:nth-child(5) {
	background: #ff6801;
}

.xhcj_ci li:nth-child(6) {
	background: #808000;
}

.xhcj_ci li:nth-child(7) {
	background: #cd282e;
}

.xhcj_ci li:nth-child(8) {
	background: #3683eb;
}





.text-time {
	padding-right: 10px;
}

.channel-title-r a {
	display: block;
	height: 15px;
	width: 30px;
	background: url("../../../assets/images/personal/icon_d.png") center center no-repeat;
	margin-top: 14px;
	cursor: pointer;
}

.el-divider--horizontal {
	margin: 0 0 12px 0;
}








@media screen and (max-width: 1440px) {
	.n_left .swiper-pagination {
		margin-top: 0;
	}

	.dizhi {
		width: 500px;
	}
}

@media screen and (max-width: 768px) {
  .w_auto {
    width: 100% !important;
  }
  .cnfin-main-news {
    .stylesOne .notice_bar_length1 {
      margin-top: 5.3333vw;
      padding-top: 2.4vw;
      display: block;
      .titlebox .titlebg {
        width: 34.6667vw;
        height: 17.3333vw;
        .floorName {
          font-size: 5.3333vw;
          width: auto;
        }
      }
      .noticebar {
        margin-left: 0;
        margin-top: 5.3333vw;
        display: block;
        .notice_item {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding: 0 2.6667vw;
          box-sizing: border-box;
          .imgnotice {
            width: auto;
            height: auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .stylesTwo .box {
      padding: 5.3333vw 2.6667vw 0;
      height: 113.3333vw;
      .n_right {
        width: 0;
      }
      .n_left {
        width: 100%;
        h3 {
          font-size: 5.3333vw;
        }
        .swiper-slide {
          div img {
            height: auto;
          }
          .article_name {
            font-size: 16px !important;
          }
          .summary {
            margin-top: 2.6667vw;
          }
        }
      }
      .moreBtn2 {
        top: 5.8667vw;
      }
    }
    .stylesTextThree {
      height: auto;
      margin-bottom: 0;
      > div:nth-child(1) {
        img {
          height: 23.3333vw !important;
        }
      }
      > div:nth-child(2) {
        display: block !important;
        padding-bottom: 8vw;
      }
      .notice_bar_length3 {
        top: -13.3333vw;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        &:nth-child(2), &:nth-child(3) {
          margin: 4vw 0 0 !important;
        }
      }
    }
  }
}
</style>